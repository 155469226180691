// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
import EmbedInstagramPost from '@bodhi-project/components/lib/instagram/EmbedInstagramPost'
import FacebookWall from '@bodhi-project/components/lib/facebook/FacebookWall'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Early Education | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    'Cambridge Sparkling Stars is an exciting aspiration of Learning Wings with a mission that children are worthy of deepest respect. They are to be provided with opportunities to discover their abilities in every area of knowledge and most importantly, be given freedom to explore at their own pace and in their own way.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
    img1: file(relativePath: { eq: "early-education/img1.jpg" }) {
      ...max900
    }
    img2: file(relativePath: { eq: "early-education/img2.jpg" }) {
      ...max900
    }
    img3: file(relativePath: { eq: "early-education/img3.jpg" }) {
      ...max900
    }
    p1: file(relativePath: { eq: "early-education/p1.jpg" }) {
      ...max900
    }
    p2: file(relativePath: { eq: "early-education/p2.jpg" }) {
      ...max900
    }
    p3: file(relativePath: { eq: "early-education/p3.jpg" }) {
      ...max900
    }
    p4: file(relativePath: { eq: "early-education/p4.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <Gallery
        data={props.data}
        lookup="img"
        columns={{ min: 3, max: 3 }}
        Img={Img}
      />
      <h1
        class="shadow"
        data-shadow="Early Education – Cambridge Sparkling Stars"
      >
        Early Education – Cambridge Sparkling Stars
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Cambridge Sparkling Stars is an exciting aspiration of Learning Wings
          with a mission that children are worthy of deepest respect. They are
          to be provided with opportunities to discover their abilities in every
          area of knowledge and most importantly, be given freedom to explore at
          their own pace and in their own way.
        </p>
        <Video
          url="https://www.youtube.com/watch?v=bMdMxu-cn20"
          className="mask-p"
        />
        <p>
          We believe that each child is capable of finding thousand ways of
          doing and understanding things, parents, school and teachers simply
          need to challenge them to excel. Sparkling Stars Playschools are to
          provide learning through loving, trusting and respectful
          relationships, and through discussion, exploration and play. With
          inquiry as goal, we've built this programmes with these ideals in
          mind:
        </p>
        <ul className="mask-p" style={{ paddingLeft: 40, listStyle: 'disc' }}>
          <li>Students as shareholders, decision takers and communicators</li>
          <li>Teachers as Co-learners, Carers and Guides</li>
          <li>Parents as partners</li>
          <li>Earth as a Home</li>
          <li>World as a Family</li>
        </ul>
        <p>
          For more information, admission opportunities, and locations please
          visit the
          <br />
          <Link to="https://www.cambridgesparklingstars.com/">
            Cambridge Sparkling Stars website ⇝
          </Link>
        </p>
      </div>
      <Division bigGolden>
        <div style={{ maxWidth: '50rem' }}>
          <h2 className="mask-h4">Programmes</h2>
          <p>
            Our curriculum is based on International standards of learning,
            centered around the belief that every child deserves the best
            possible start in life and the support to fulfill their potential.
            The curriculum has a thematic and integrated approach towards
            developmental milestones. The year is divided into four themes and
            each theme has elaborated cycles with fifteen days curriculum
            planning. The concepts and subjects are integrated with topics and
            sub topics together to make interesting, meaningful and applied
            learning. Key programs –
          </p>
          <Division threeQuartersAlt>
            <div className="desktop-only">
              <Img
                style={{
                  boxShadow: '6px 6px 0px #f4c220',
                }}
                fluid={props.data.p1.childImageSharp.fluid}
              />
              <br />
            </div>
            <div className="copy">
              <h2 className="mask-h4">Infants & Toddlers</h2>
              <p>
                Cambridge sparkling stars’ curriculum is theme based, which is
                designed to foster age appropriate growth and learning
                challenges. The curriculum stimulate children and prepare them
                for Kindergarten. It has integrated approach to numeracy,
                language, environmental studies, art and craft, social/emotional
                and physical development. Teaching and learning takes place
                through a broad range of activities including interactive,
                motor, listening, sensory experiences, and field trips to
                encourage children in hands on learning. It is a fairly equal
                combination of play based and sit-down learning.
              </p>
              <p>
                <strong>Age: 18 - 24 Months (Infants)</strong>
                <br />
                <strong>
                  <span style={{ visibility: 'hidden' }}>Age:</span> 24 - 36
                  Months (Toddlers)
                </strong>
              </p>
            </div>
          </Division>
          <Division threeQuartersAlt>
            <div className="desktop-only">
              <Img
                style={{
                  boxShadow: '6px 6px 0px #2052f4',
                }}
                fluid={props.data.p2.childImageSharp.fluid}
              />
              <br />
            </div>
            <div className="copy">
              <h2 className="mask-h4">Foundation (Nursery)</h2>
              <p>
                Children between 3 years – 4 years are provided with a wide
                variety of learning experiences where they are actively engaged.
                They have opportunities to ask questions, investigate practical
                problems and take increasing responsibility of what they do.
                Children develop knowledge and understanding of the learning
                area being explored and the application of concepts.
              </p>
              <p>
                We provide our children with a creative and integrated
                curriculum which is based on EYFS areas of learning –
                communication, literacy, Mathematics, Understanding the World,
                Technology, Physical Development, Personal and social
                Development, Creative Development and Performing Arts.
              </p>
              <p>
                <strong>Age: 3 - 4 Years</strong>
              </p>
            </div>
          </Division>
          <Division threeQuartersAlt>
            <div className="desktop-only">
              <Img
                style={{
                  boxShadow: '6px 6px 0px #5820f4',
                }}
                fluid={props.data.p3.childImageSharp.fluid}
              />
              <br />
            </div>
            <div className="copy">
              <h2 className="mask-h4">Beginners (KG 1)</h2>
              <p>
                Cambridge Sparking Stars Beginners Curriculum (age 4-5 years)
                framework is based on the set of learning goals in six learning
                areas which are integrated by planning co-curricular activities
                with a detailed description. The curriculum is designed to
                address all the aspects of children’s development accordingly.
                It includes experiences to enhance their social, emotional,
                language, creative and physical development. It identifies their
                preferred ways of interacting with the environment, frequency of
                conversation and choices to work individually or with the peer
                group. It culminates the thorough review of interactive lessons,
                worksheets, quiz and assessments to develop skills in a
                meaningful context and to acquire competence as life-long
                learners
              </p>
              <p>
                <strong>Age: 4 - 5 Years</strong>
              </p>
            </div>
          </Division>
          <Division threeQuartersAlt>
            <div className="desktop-only">
              <Img
                style={{
                  boxShadow: '6px 6px 0px #20f4c2',
                }}
                fluid={props.data.p4.childImageSharp.fluid}
              />
              <br />
            </div>
            <div className="copy">
              <h2 className="mask-h4">Advanced (KG 2)</h2>
              <p>
                A comprehensive and integrated curriculum based on EYFS forms
                the framework of learning at Advanced (KG 2) level. The teaching
                pedagogies amalgamated with fun filled activities give the
                children an opportunity to express, explore, experiment with
                innovative ideas and fosters confident learning.
              </p>
              <p>
                The children between the age group of 5-6 years are provided
                with such an environment which facilitates effective and clear
                communication , enhances analytical thinking for conceptual
                clarity , enables basic understanding of World around us,
                Personal, Social and Emotional development. Co-curricular
                activities further redefine and refine Physical and Creative
                development along with Performing Arts.
              </p>
              <p>
                <strong>Age: 5 - 6 Years</strong>
              </p>
            </div>
          </Division>
        </div>
        <div>
          <h2 className="mask-h4">Updates from the ground</h2>
          <p>
            Cambridge Sparkling Stars aims at creating a learning environment
            where young minds are free to progress at their own pace and
            learning takes place in a nontraditional student centered
            environment. An environment where minds are nurtured and move on to
            the next level with confidence, ease and higher order thinking.
          </p>
          <h2 className="mask-h5">On Instagram</h2>
          <EmbedInstagramPost url="https://www.instagram.com/p/Bse7A3LHgVx/" />
          <h2 className="mask-h5">On Facebook</h2>
          <FacebookWall url="https://www.facebook.com/cambridgesparklingstars/" />
          <h2 className="mask-h4">Key locations</h2>
          <p>
            Colourfully designed infrastructure, innovative play areas, child
            friendly and safe, our key locations –
          </p>
          <ul className="mask-p" style={{ paddingLeft: 40, listStyle: 'disc' }}>
            <li>Cambridge Sparkling Stars (Foundation), Jalandhar</li>
            <li>Cambridge Sparkling Stars for Girls, Jalandhar</li>
            <li>Cambridge Sparkling Stars, New Delhi</li>
            <li>Cambridge Sparkling Stars, Garshankar</li>
          </ul>
          <p>
            <Link to="https://www.cambridgesparklingstars.com/our-schools">
              Learn more ⇝
            </Link>
          </p>
        </div>
      </Division>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
